import { Component, Input, OnInit } from '@angular/core';
import { CurrentUserService, DeviceDataService } from '@desquare/services';
import moment from 'moment';
import { Observable, map, tap } from 'rxjs';
import { ChartType } from '@desquare/enums';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeviceData } from '@desquare/types';
import { Maybe } from '@designage/gql';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageEchartComponent } from '../designage-echart/designage-echart.component';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, DesignageEchartComponent],
  selector: 'device-historical-dashboard',
  templateUrl: './device-historical-dashboard.component.html',
  styleUrls: ['./device-historical-dashboard.component.scss'],
})
export class DeviceHistoricalDashboardComponent implements OnInit {
  @Input() modal!: NgbModalRef;
  @Input() deviceId!: string;

  readonly ChartType = ChartType;
  statusDay = moment().format('YYYY-MM-DD');
  dayData!: [string, number][];
  deviceFullStatusData$!: Observable<[string, number][]>; //The observable that holds the status data
  deviceTempDataForEchart$!: Observable<[string, number][]>; //The observable that holds the temp data
  deviceCpuDataForEchart$!: Observable<[string, number][]>; //The observable that holds the cpu data
  device!: Maybe<DeviceData>;

  constructor(
    private deviceDataService: DeviceDataService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.initData(this.deviceId, this.currentUserService.getCurrentProfileId());
    this.device = this.deviceDataService.getDevice(this.deviceId);
    console.log('device', this.deviceDataService.getDevice(this.deviceId));
  }
  async initData(deviceId: string, profileId: string) {
    this.deviceFullStatusData$ = this.deviceDataService
      .getDeviceMqttStatus$(profileId, deviceId)
      .pipe(
        map((data) => {
          const dataArray: [string, number][] = Object.values(data).map(
            (item) => {
              return [moment(item.ts).toISOString(), item.online ? 1 : 0];
            }
          );
          return dataArray;
        }),
        tap((data) => {
          this.dayData = data.filter((d) =>
            moment(moment(d[0]).format('YYYY-MM-DD')).isSame(
              moment(this.statusDay)
            )
          );
          // console.log('dayData after', this.dayData);
        })
      );

    this.deviceTempDataForEchart$ = this.deviceDataService
      .getDeviceMqttInfo(profileId, deviceId)
      .pipe(
        map((data) =>
          data.filter((item) => item.temp != null && item.temp != 0)
        )
      )
      .pipe(
        map((data) => {
          const dataArray: [string, number][] = Object.values(data).map(
            (item) => {
              return [moment(item.ts).toISOString(), item.temp || 0];
            }
          );
          return dataArray;
        })
      );

    this.deviceCpuDataForEchart$ = this.deviceDataService
      .getDeviceMqttInfo(profileId, deviceId)
      .pipe(
        map((data) => data.filter((item) => item.cpu != null && item.cpu != 0))
      )
      .pipe(
        map((data) => {
          const dataArray: [string, number][] = Object.values(data).map(
            (item) => {
              return [moment(item.ts).toISOString(), item.cpu || 0];
            }
          );
          return dataArray;
        })
      );
  }

  onChartClick(event: any) {
    this.statusDay = moment(event[0]).format('YYYY-MM-DD');

    // this.deviceFullStatusData$.pipe(
    //   tap((data) => {
    //     this.dayData = data.filter((d) =>
    //       moment(moment(d[0]).format('YYYY-MM-DD')).isSame(
    //         moment(this.statusDay)
    //       )
    //     );
    //     console.log('dayData after', this.dayData);
    //   })
    //   )
    // console.log('chart click', this.statusDay);
  }
}
