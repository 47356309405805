<div class="ps-2 h-100 d-flex flex-column">
  <!-- header section -->
  <div class="row">
    <div
      class="text-truncate col-12 d-flex align-items-center justify-content-between sliding-panel-header"
    >
      <label class="d-inline pointer-text">
        {{ layoutManageForm?.value?.detailSettingsForm?.name || '' }}
      </label>
      <div style="padding-right: 5rem !important">
        <button
          class="btn btn-md btn-outline-success mx-2"
          (click)="submit(true)"
          [hidden]="!isModal"
        >
          {{ 'SAVE_AND_CLOSE' | translate }}
        </button>
        <button
          class="btn btn-md btn-outline-success mx-2"
          (click)="submit()"
          [hidden]="isModal"
        >
          {{ 'SAVE' | translate }}
        </button>

        <button
          class="btn btn-md btn-outline-danger mx-2"
          [hidden]="isModal"
          (click)="onDeleteButtonClicked()"
        >
          {{ 'DELETE' | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- layout form -->
  <app-layout-form
    class="h-100 pt-4"
    [parentFormGroup]="layoutManageForm"
    [isManageMode]="true"
  ></app-layout-form>
  <!-- <app-zone-splitter></app-zone-splitter> -->
</div>
