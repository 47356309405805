import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Maybe } from 'graphql/jsutils/Maybe';

interface IDropdownItem {
  id: number;
  name: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, TranslateModule],
  selector: 'app-dropdown-menu',
  template: `<label class="d-inline-block dropdown-label"
      >{{ label | translate }}:</label
    >
    <div
      class="labeled-value"
      *ngIf="items.length > 0; else empty"
      ngbDropdown
      class="d-inline-block"
    >
      <div
        id="channelDropdown"
        class="channel-selector no-caret"
        ngbDropdownToggle
      >
        <label class="dropdown-title">
          <span>{{ selectedItem.name }}</span>
          <i class="label-icon mdi mdi-chevron-down"></i>
        </label>
      </div>
      <div
        ngbDropdownMenu
        aria-labelledby="channelDropdown"
        class="dropdown-container shadowed-box"
      >
        <button
          ngbDropdownItem
          class="text-white menu-item"
          [ngClass]="{ 'menu-item-selected': item === selectedItem }"
          *ngFor="let item of displayableItems; let i = index"
          (click)="itemClick(i)"
        >
          {{ item.name }}
        </button>
        <button
          *ngIf="displayShowAllButton && items.length > maxThreshold"
          (click)="showAllClick()"
          class="btn btn-link"
        >
          {{ 'SHOW_ALL_TOTAL' | translate : { total: items.length } }}
        </button>
      </div>
    </div>

    <ng-template #empty>
      <h5 class="labeled-value d-inline-block">
        {{ nullMessagePrompt | translate }}
      </h5>
    </ng-template> `,
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent {
  @Input() items: IDropdownItem[] = [];
  @Input() selectedItem!: IDropdownItem;
  @Input() loading = true;
  @Input() nullMessagePrompt: Maybe<string>;
  @Input() label: Maybe<string>;
  @Input() displayShowAllButton = true;
  @Input() maxThreshold = 10;
  @Input() options: string[] = [];

  @Output() selectedItemChanges = new EventEmitter<number>();
  @Output() showAllTrigger = new EventEmitter();

  get displayableItems() {
    return this.displayShowAllButton
      ? this.items.filter((item, index) => index < this.maxThreshold)
      : this.items;
  }

  constructor() {}

  itemClick(index: number) {
    this.selectedItem = this.items[index];
    this.selectedItemChanges.emit(index);
  }

  showAllClick() {
    this.showAllTrigger.emit();
  }
}
