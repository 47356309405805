export enum ScreenStatus {
  DISPLAY_ON = 'DISPLAY_ON',
  DISPLAY_OFF = 'DISPLAY_OFF',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OFFLINE = 'OFFLINE',
  UPDATING = 'UPDATING',
}

export enum ScreenAppStatus {
  UPDATED = 'UPDATED',
  OUTDATED = 'OUTDATED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  UPDATING = 'UPDATING',
}
