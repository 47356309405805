import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule],
  selector: 'app-basic-dialog',
  template: `<div class="modal-container">
    <div class="modal-header p-2">
      <h3 class="d-inline">{{ headerText | translate }}</h3>
      <button class="btn btn-outline-secondary float-end" (click)="close()">
        X
      </button>
    </div>
    <div class="modal-body">
      <div #bodyWrapper>
        <ng-content></ng-content>
      </div>
    </div>
  </div> `,
})
export class BasicDialogComponent {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  @Input() headerText = '';

  constructor(public modal: NgbModal) {}

  close() {
    this.modal.dismissAll();
  }
}
