import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AngularSplitModule } from 'angular-split';
import { BasicDialogComponent } from './basic-dialog/basic-dialog.component';
import { DateProxyPipe } from './pipe/pipe/date-proxy.pipe';
import { DeviceCommandsComponent } from './device-commands/device-commands.component';
import { CloudinaryModule } from '@cloudinary/ng';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { DurationPipe } from './pipe/duration/duration.pipe';
import { FileSizePipe } from './pipe/file-size/file-size.pipe';
import { FooterComponent } from './footer/footer.component';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { SafePipe } from './pipe/safe/safe.pipe';
import { SearchInputComponent } from './search-input/search-input.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@desquare/factories';
import { TypeaheadComponent } from './typeahead/typeahead.component';

import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { CreateFolderDialogComponent } from '@desquare/components/common/src/modals/create-folder-dialog.component';
import { UpdateFolderDialogComponent } from '@desquare/components/common/src/modals/update-folder-dialog.component';
import { MoveMediaFolderDialogComponent } from '@desquare/components/common/src/modals/move-media-folder-dialog.component';
import { FolderTreeComponent } from './folder/folder-tree/folder-tree.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';

import { DeleteFolderDialogComponent } from '@desquare/components/common/src/modals/delete-folder-dialog.component';
import { MoveFolderToFolderDialogComponent } from '@desquare/components/common/src/modals/move-folder-to-folder-dialog.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { ClipboardModule } from 'ngx-clipboard';

import { FolderExplorerComponent } from './folder/folder-explorer/folder-explorer.component';
import { DeleteMediaDialogComponent } from './media/delete-media-dialog/delete-media-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WifiStrengthIndicatorComponent } from './wifi-strength-indicator/wifi-strength-indicator.component';
import { TypeofPipe } from './pipe/typeof/typeof.pipe';
import { DeviceDetailsTableComponent } from './device-details-table/device-details-table.component';
import { MomentModule } from 'ngx-moment';
import { DesignageEchartComponent } from '@desquare/components/common/src/designage-echart/designage-echart.component';
import { DeviceHistoricalDashboardComponent } from './device-historical-dashboard/device-historical-dashboard.component';
import { CloudinaryMediaComponent } from './cloudinary/cloudinaryMedia.component';
import { TableRowSelectionDialogComponent } from './tablerow-selection-dialog/tablerow-selection-dialog.component';
import { SmartDateTimeComponent } from './smart-date-time/smart-date-time.component';
import { DeviceLogComponent } from './device-log/device-log.component';
import { DeviceConnectedLinksComponent } from './device-connected-links/device-connected-links.component';
import { DesignageDataTableComponent } from './designage-data-table/designage-data-table.component';
import { TableDateTimeComponent } from './designage-data-table/table-components/table-dateTime.component';
import { PlaylistManageDialogComponent } from '@desquare/components/common/src/modals/playlist-manage-dialog.component';
import { DeviceBrightnessComponent } from './device-ui-components/device-brightness.component';
import { DeviceVolumeComponent } from './device-ui-components/device-volume.component';

export const ngxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    TableRowSelectionDialogComponent,
    DeviceHistoricalDashboardComponent,
    TypeofPipe,
    DeleteMediaDialogComponent,
    SafePipe,
    FooterComponent,
    DropdownMenuComponent,
    WifiStrengthIndicatorComponent,
    DeviceConnectedLinksComponent,
    BasicDialogComponent,
    DeviceLogComponent,
    TypeaheadComponent,
    DeviceDetailsTableComponent,
    SmartDateTimeComponent,
    AngularSplitModule,
    DeviceCommandsComponent,
    CommonModule,
    DateProxyPipe,
    DesignageDataTableComponent,
    TableDateTimeComponent,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CloudinaryModule,
    // CloudinaryModule.forRoot(cloudinaryLib, {
    //   cloud_name: environment.cloudinary.cloudName as string,
    // }),
    ReactiveFormsModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    }),
    CdkTableModule,
    CdkTreeModule,
    MatCheckboxModule,
    ClipboardModule,
    // DesignageDataTableModule,
    MatCheckboxModule,
    MomentModule,
    FolderTreeComponent,
    DeviceBrightnessComponent,
    DeviceVolumeComponent,
    FormDialogComponent,
    ConfirmDialogComponent,
    TimepickerComponent,
    DurationPipe,
    BaseDialogComponent,
    DatepickerComponent,
    PlaylistManageDialogComponent,
    MoveMediaFolderDialogComponent,
    MoveFolderToFolderDialogComponent,
    CreateFolderDialogComponent,
    UpdateFolderDialogComponent,
    DeleteFolderDialogComponent,
    DesignageEchartComponent,
    LoaderComponent,
    SearchInputComponent,
    FolderExplorerComponent,
    CloudinaryMediaComponent,
    CopyToClipboardComponent,
    FileSizePipe,
],
  exports: [
    AngularSplitModule,
    BasicDialogComponent,
    CdkTableModule,
    MatCheckboxModule,
    ConfirmDialogComponent,
    CloudinaryModule,
    DatepickerComponent,
    DateProxyPipe,
    DeviceCommandsComponent,
    DeviceConnectedLinksComponent,
    DropdownMenuComponent,
    DurationPipe,
    FileSizePipe,
    FormDialogComponent,
    FormsModule,
    FooterComponent,
    HttpClientModule,
    LoaderComponent,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    SafePipe,
    SearchInputComponent,
    SmartDateTimeComponent,
    TimepickerComponent,
    TranslateModule,
    TypeaheadComponent,
    BaseDialogComponent,
    CreateFolderDialogComponent,
    MoveMediaFolderDialogComponent,
    FolderTreeComponent,
    DeleteFolderDialogComponent,
    MoveFolderToFolderDialogComponent,
    FolderExplorerComponent,
    CopyToClipboardComponent,
    DeleteMediaDialogComponent,
    DesignageDataTableComponent,
    TableDateTimeComponent,
    TypeofPipe,
    MatCheckboxModule,
    WifiStrengthIndicatorComponent,
    DeviceDetailsTableComponent,
    DesignageEchartComponent,
    DeviceHistoricalDashboardComponent,
    CloudinaryMediaComponent,
    TableRowSelectionDialogComponent,
    DeviceLogComponent,
    PlaylistManageDialogComponent,
    DeviceBrightnessComponent,
    DeviceVolumeComponent,
],
  providers: [provideNgxMask(ngxMaskOptions)],
})
export class DesquareCommonModule {}
