import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  inject,
} from '@angular/core';
import { Maybe } from '@designage/gql';
import { BaseDialogComponent } from '@desquare/components/common/src/base-dialog/base-dialog.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutCreateComponent } from '../layout-create/layout-create.component';
import { LayoutManageComponent } from '../layout-manage/layout-manage.component';

@Component({
  standalone: true,
  imports: [
    BaseDialogComponent,
    TranslateModule,
    LayoutCreateComponent,
    LayoutManageComponent,
  ],
  selector: 'designage-layout-manage-dialog',
  template: `<app-base-dialog
    [headerText]="
      id !== 'new' ? ('EDIT_LAYOUT' | translate) : ('NEW_LAYOUT' | translate)
    "
    [dismissOverride]="dismissFunction"
  >
    @if(id){
    <div class="modal-body">
      @if(adding()){
      <app-layout-create [isModal]="true"></app-layout-create>} @if(editing()){
      <app-layout-manage
        [inlineLayoutId]="id"
        [isModal]="true"
      ></app-layout-manage>
      }
    </div>
    }
  </app-base-dialog> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutManageDialogComponent {
  modal = inject(NgbActiveModal);
  @Input() id!: Maybe<string>;

  adding = computed(() => !this.editing());
  editing = computed(() => this.id !== 'new');

  dismissFunction = () => {
    console.log('dismissFunction');
    this.modal.dismiss();
  };
}
