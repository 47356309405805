import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import type { ECharts, EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import { ChartType } from '@desquare/enums';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { CommonModule } from '@angular/common';

export type graphData = {
  name: string;
  value: string;
};

@Component({
  standalone: true,
  imports: [NgxEchartsDirective, CommonModule],
  selector: 'designage-echart',
  providers: [provideEcharts()],
  template: `<div
    echarts
    [options]="chartOption"
    (chartInit)="onChartInit($event)"
    [style.height.px]="height"
    class="echart"
  ></div> `,
  styles: [
    `
      .echart {
        width: 100%;
      }
    `,
  ],
})
export class DesignageEchartComponent implements OnInit, OnChanges {
  @Input('chartType') chartType!: ChartType;
  @Input('data') inputData!: [string, number][];
  @Input('timespanMinutes') timespanMinutes: number = 1440;
  @Input('dayZoom') dayZoom?: string;
  @Input('height') height: number = 128;

  @Output() onChartClick: any = new EventEmitter();

  // chartOption!: EChartsOption;
  echartsInstance?: ECharts;

  chartOption!: EChartsOption;

  chartOptionTemplate: EChartsOption = {
    textStyle: {
      color: '#fff',
      fontFamily: 'Nunito',
      fontSize: 16,
    },
    grid: {
      top: 10,
      left: '12%',
    },
    tooltip: {
      trigger: 'axis',
    },
    dataZoom: [
      {
        type: 'slider',
        rangeMode: ['value', 'value'],
        startValue: 0,
        endValue: moment().toISOString(),
        brushSelect: false,
        moveHandleSize: 15,
        backgroundColor: '#323b44',
        borderColor: '#323b44',
        labelFormatter: (value) => {
          return moment(value).format('HH:mm');
        },
        showDetail: true,
        showDataShadow: false,
        height: 15,
        textStyle: {
          color: '#fff',
          fontFamily: 'Nunito',
          fontSize: 12,
        },
      },
      {
        type: 'inside',
        startValue: moment()
          .subtract(this.timespanMinutes, 'minutes')
          .toISOString(),
        endValue: moment().toISOString(),
      },
    ],
    xAxis: {
      type: 'time',
      position: 'bottom',
      splitLine: {
        show: true,
        lineStyle: { opacity: 0.2, color: '#82909E' },
      },
      max: () => {
        return moment().toISOString();
      },
      min: () => {
        return moment().subtract(this.timespanMinutes, 'minutes').toISOString();
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: '#fff',
        formatter: (axisValue: number) => {
          return moment(axisValue).format('HH:mm');
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#fff',
      },
      alignTicks: true,
      splitLine: {
        show: true,
        lineStyle: { opacity: 0.2, color: '#82909E' },
      },
    },
    series: {
      type: 'line',
      lineStyle: {
        color: '#323b44',
        width: 1,
        opacity: 1,
      },
      showSymbol: false,
      itemStyle: {
        opacity: 0.5,
        borderWidth: 0,
        borderColor: '#fff',
      },
    },
  };

  ngOnInit(): void {
    switch (this.chartType) {
      case ChartType.UPTIME:
        this.chartOption = _.merge(
          this.chartOptionTemplate,
          this.upTimeChart()
        );
        // console.log('uptime input', this.inputData); //DEBUG

        break;
      case ChartType.TEMP:
        this.chartOption = _.merge(this.chartOptionTemplate, this.tempChart());
        // console.log('temp input', this.inputData); //DEBUG

        break;
      case ChartType.CPU:
        this.chartOption = _.merge(this.chartOptionTemplate, this.cpuChart());
        // console.log('cpu input', this.inputData); //DEBUG
        break;

      case ChartType.UPTIMEFULL:
        this.chartOption = this.upTimeChartFull();
        // console.log('uptimeFull input', this.inputData); //DEBUG
        break;

      default:
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('generic changes', changes); // DEBUG

    if (changes.timespanMinutes && !changes.timespanMinutes.firstChange) {
      // console.log('changes timespanMinutes', changes); // DEBUG
      this.echartsInstance?.setOption({
        dataZoom: {
          startValue: () =>
            moment().subtract(this.timespanMinutes, 'minutes').toISOString(),
          endValue: () => moment().toISOString(),
        },
        xAxis: {
          min: () =>
            moment().subtract(this.timespanMinutes, 'minutes').toISOString(),
        },
      });
    }

    if (
      this.dayZoom &&
      changes.dayZoom &&
      this.chartType !== ChartType.UPTIMEFULL
    ) {
      console.log(this.chartType, 'changes dayZoom', changes); // DEBUG
      this.echartsInstance?.setOption({
        dataZoom: {
          startValue: () =>
            moment(changes.dayZoom.currentValue).startOf('day').toISOString(),
          endValue: () =>
            moment(changes.dayZoom.currentValue).endOf('day').toISOString(),
        },
        xAxis: {
          min: () =>
            moment(changes.dayZoom.currentValue).startOf('day').toISOString(),
          max: () =>
            moment(changes.dayZoom.currentValue).endOf('day').toISOString(),
        },
      });
    }

    if (changes.inputData) {
      console.log(this.chartType, 'changes inputData', changes); // DEBUG
      this.echartsInstance?.setOption({
        series: [
          {
            data:
              this.chartType === ChartType.UPTIMEFULL
                ? this.dayStatChecker(changes.inputData.currentValue)
                : changes.inputData.currentValue,
          },
        ],
      });
    }
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    if (this.chartType === 'uptime' && this.dayZoom)
      this.echartsInstance.setOption({
        dataZoom: {
          startValue: moment(this.dayZoom).startOf('day').toISOString(),
          endValue: moment(this.dayZoom).startOf('day').toISOString(),
        },
      });

    this.echartsInstance.on('click', (params) => {
      this.onChartClick.emit(params.value);
    });
  }

  upTimeChart() {
    const uptimeConfig: EChartsOption = {
      grid: {
        show: true,
        // backgroundColor: '#f1556c',
      },
      tooltip: {
        show: false,
        trigger: 'item',
        valueFormatter: (value) => (value === 1 ? 'Online' : 'Offline'),
      },
      dataZoom: [
        {
          filterMode: 'none',
        },
        {
          filterMode: 'none',
        },
      ],
      xAxis: {
        axisPointer: {
          show: true,
          snap: true,
          label: {
            formatter: (params) => {
              // console.log('params', params);
              const status = params.seriesData[0].value as [string, number];
              return `${status[1] === 1 ? 'online' : 'offline'}: ${moment(
                params.value
              ).format('HH:mm:ss')}`;
            },
            backgroundColor: '#323b44',
            shadowBlur: 10,
            shadowColor: '#323b44',
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        },
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        splitNumber: 1,
        axisLabel: {
          formatter: (axisValue: number) => {
            return axisValue === 1 ? 'Online' : 'Offline';
          },
        },
      },
      series: {
        data: this.inputData,
        type: 'line',
        step: 'end',
        areaStyle: { color: '#178863', opacity: 1 },
        tooltip: {},
        lineStyle: {
          color: '#fff',
          width: 1,
          opacity: 0.7,
        },
        itemStyle: {
          color: (param) => {
            const value = param.data as [string, number];
            return value[1] === 1 ? '#178863' : '#f1556c';
          },
          opacity: 1,
          borderWidth: 0,
          borderColor: '#fff',
        },
      },
    };
    return uptimeConfig;
  }

  upTimeChartFull() {
    return {
      grid: { height: this.height - 100 },
      tooltip: {
        show: true,
      },
      calendar: {
        itemStyle: {
          opacity: 1,
          color: '#323b44',
          borderColor: '#404D59',
          borderWidth: 0.5,
          borderType: 'dotted',
        },
        top: 'middle',
        // left: 'center',
        orient: 'horizontal',
        cellSize: ['auto', 25],
        splitLine: {
          show: true,
          lineStyle: { opacity: 1, color: '#404D59', borderWidth: 2 },
        },
        yearLabel: {
          show: false,
          fontSize: 30,
        },
        dayLabel: {
          color: '#fff',
          margin: 20,
          firstDay: 1,
          nameMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        monthLabel: {
          color: '#fff',
          margin: 20,
          show: true,
        },
        range: [
          moment().subtract(2, 'month').format('YYYY-MM'),
          moment().endOf('month').format('YYYY-MM-DD'),
        ],
      },
      visualMap: {
        show: false,
        min: 1,
        max: 3,
        inRange: {
          color: ['#f1556c', '#c38822', '#407241'],
        },
      },
      series: [
        {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: this.dayStatChecker(this.inputData),
          itemStyle: {
            borderWidth: 0,
            borderColor: '#fff',
          },
          selectedMode: 'single',
          select: {},
          tooltip: {
            formatter: (params: any) => {
              return `select ${params.value[0]}`;
            },
          },
        },
      ],
    } as EChartsOption;
  }

  tempChart() {
    return {
      tooltip: {
        valueFormatter: (value: number) => `${value}°C`,
      },
      yAxis: {
        scale: true,
        min: (value) => value.min - 2,
        max: (value) => value.max + 2,
        splitNumber: 4,
      },
      series: {
        name: 'System temp',
        data: this.inputData,
        type: 'line',
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: '#3bafda',
            },
            {
              offset: 0,
              color: '#f1556c',
            },
          ]),
        },
      },
    } as EChartsOption;
  }

  cpuChart() {
    return {
      tooltip: {
        valueFormatter: (value: number) => `${value}%`,
      },
      yAxis: {
        min: 25,
        splitNumber: 4,
      },
      series: {
        name: 'CPU Usage',
        data: this.inputData,
        type: 'line',
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: '#3bafda',
            },
            {
              offset: 0,
              color: '#f1556c',
            },
          ]),
        },
      },
    } as EChartsOption;
  }

  dayStatChecker(inputData: [string, number][]) {
    const entriesByDay = _.groupBy(inputData, (d) =>
      moment(d[0]).format('YYYY-MM-DD')
    );
    const groupedByDay: [string, number][] = [];
    Object.keys(entriesByDay).forEach((key) => {
      const onlines: number = entriesByDay[key].filter(
        (d) => d[1] === 1
      ).length;
      const offlines: number = entriesByDay[key].filter(
        (d) => d[1] === 0
      ).length;

      if (onlines > 0 && offlines === 0) groupedByDay.push([key, 3]);
      if (onlines > 0 && offlines > 0) groupedByDay.push([key, 2]);
      if (onlines === 0 && offlines > 0) groupedByDay.push([key, 1]);
    });
    return groupedByDay;
  }
}
