import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { CreateLayoutInput } from '@designage/gql';
import {
  ICloudinaryUploadResponse,
  ILayoutEditorCanvas,
  ILayoutEditorSource,
} from '@desquare/interfaces';
import {
  CurrentUserService,
  LayoutDataService,
  LayoutEditorService,
  ToasterService,
} from '@desquare/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { LayoutFormComponent } from '../layout-form/layout-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  imports: [NgClass, LayoutFormComponent, TranslateModule],
  selector: 'app-layout-create',
  template: `<div
    class="container-fluid content-container-card d-flex flex-column"
  >
    <!-- header section -->
    <div class="d-flex justify-content-between">
      <h1
        style="height: 2.2rem"
        [class.text-secondary]="
          !layoutCreateForm?.value?.detailSettingsForm?.name
        "
      >
        {{ layoutCreateForm?.value?.detailSettingsForm?.name || '...' }}
      </h1>
      <div class="d-flex align-items-center">
        <button
          type="submit"
          class="btn btn-md btn-outline-success mx-2"
          (click)="submit()"
        >
          {{ (isModal ? 'SAVE_AND_CLOSE' : 'SAVE') | translate }}
        </button>
      </div>
    </div>

    <!-- DEBUG -->
    <!-- <pre style="color: wheat"> {{ layoutCreateForm.value | json }}</pre> -->

    <!-- layout form -->
    <app-layout-form
      [parentFormGroup]="layoutCreateForm"
      class="h-100"
    ></app-layout-form>
  </div> `,
  providers: [LayoutEditorService],
})
export class LayoutCreateComponent implements OnDestroy {
  private subs = new SubSink();

  @Input() isModal = false;

  layoutCreateForm = new UntypedFormGroup({});

  cloudinaryUploadResponse?: ICloudinaryUploadResponse;

  constructor(
    private currentUserService: CurrentUserService,
    private layoutDataService: LayoutDataService,
    private layoutEditorService: LayoutEditorService,
    private modal: NgbActiveModal,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async submit() {
    const profileId = this.currentUserService.currentProfile?.id;
    if (!profileId) return console.error('no current profileId');

    const { detailSettingsForm } = this.layoutCreateForm.value;

    // stop submit if one of the forms inside layoutCreateForm is invalid
    if (this.layoutCreateForm.invalid) {
      this.toasterService.error('Invalid Form');
      return;
    }

    const createLayoutNameInput: string = detailSettingsForm.name;
    const createLayoutDescriptionInput: string = detailSettingsForm.description;

    // save scene to cloudinary
    const cloudinaryUploadResponse =
      await this.layoutEditorService.saveSceneToCloudinary();

    // create canvas
    const canvas: ILayoutEditorCanvas =
      this.layoutEditorService.extractToILayoutEditorCanvas();

    // create source
    const source: ILayoutEditorSource =
      this.layoutEditorService.toLayoutEditorSource(
        cloudinaryUploadResponse,
        canvas
      );

    // create CreateLayoutInput
    const createLayoutInput: CreateLayoutInput = {
      name: createLayoutNameInput,
      description: createLayoutDescriptionInput,
      profileId,
      source,
    };

    // console.log('createLayoutInput: ', createLayoutInput); // DEBUG

    this.subs.sink = this.layoutDataService
      .createLayout(createLayoutInput)
      .subscribe({
        next: (result) => {
          const { data } = result;
          if (data) {
            const { isSuccessful, layout } = data.createLayout;

            if (isSuccessful) {
              this.toasterService.success('CREATE_LAYOUT_SUCCESS');

              // refetch layouts
              this.layoutDataService.profileLayoutsQuery?.refetch({
                profileId: this.currentUserService.currentProfile?.id,
              });

              // console.log('created layout: ', layout);
              // navigate back
              if (this.isModal) {
                this.modal.close(layout);
              } else {
                this.router.navigate(['layouts']);
              }
            }
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('Layout Create', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        },
      });
  }
}
