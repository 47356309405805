import { Component } from '@angular/core';
import { Channel, Maybe } from '@designage/gql';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormDialogComponent } from './form-dialog.component';
import { DesquareCommonModule } from '../desquare-common.module';

@Component({
  imports: [
    CommonModule,
    TranslateModule,
    FormDialogComponent,
    DesquareCommonModule,
  ],
  standalone: true,
  selector: 'app-delete-channel-dialog',
  template: `
    <app-confirm-dialog
      [useDangerButton]="true"
      [closeButtonEnabled]="name === channel?.name"
      closeButtonText="PROCEED"
      dismissButtonText="CANCEL"
    >
      <p class="danger">
        {{ 'DELETE_CHANNEL_PROMPT' | translate }}
        {{ 'ENTER_CHANNEL_NAME' | translate }}
        <q>{{ channel?.name }}</q>
        .
      </p>

      <div class="form-group">
        <input
          [(ngModel)]="name"
          class="form-control form-control-dark"
          placeholder="{{ channel?.name }}"
        />
      </div>
    </app-confirm-dialog>
  `,
})
export class DeleteChannelDialogComponent {
  channel?: Maybe<Channel>;
  name?: Maybe<string>;
}
